import { catchError, filter, switchMap } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { ServingMenuModel } from '@/models';
import { UpdateServingMenuPayload } from "@/store/slices/staff/serving-menus.slice";

export const updateServingMenuEpic: PayloadEpic<UpdateServingMenuPayload, ServingMenuModel[]> = (action$) => {
    return action$.pipe(
        filter(asyncActions.servingMenus.update.match),
        switchMap(({ payload }) => concat(
            of(actions.servingMenus.setIsSaving(true)),
            updateServingMenu(payload).pipe(
                switchMap(({ data }) => from([
                    actions.servingMenus.upsertOne(data),
                ])),
                catchError((error) => {
                    console.error('updatingServingMenuEpic', error);

                    return of(actions.servingMenus.setSavingErrors(error.response.data));
                }),
            ),
            of(actions.servingMenus.setIsSaving(false)),
        )),
    );
};

const updateServingMenu = (data: UpdateServingMenuPayload) => {
    return axios.put<ServingMenuModel>(apiEndpoints.servingMenu(data.id), data);
};
