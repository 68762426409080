export type ObjectEntries<T> = ([keyof T, T[keyof T]])[];

export const getEntries = <T>(obj: T) => Object.entries(obj) as ObjectEntries<T>;
export const getOwnPropertyNames = <T>(obj: T) => Object.getOwnPropertyNames(obj) as (keyof T)[];
export const getKeys = <T>(obj: T) => Object.keys(obj) as (keyof T)[];

export const isInList = <L>(value: any, list: L[]): value is L => list.includes(value);

export const identity = <T>(value: T) => value;
export const jsonClone = <T>(value: T): T => JSON.parse(JSON.stringify(value));

export const isObject = (value: any): value is object => !!value && typeof value === 'object';
