import lightJoin from 'light-join';
import { EntityId } from '@reduxjs/toolkit';

export namespace apiEndpoints {
    export const accounts = () => '/api/accounts/';
    export const profile = () => lightJoin(accounts(), 'profile/');
    export const logout = () => lightJoin(accounts(), 'logout/');

    export const organization = (orgUid: EntityId) => lightJoin('/api/organizations/', `${orgUid}/`);

    export const publicContracts = () => '/api/sales-contracts/';
    export const publicContract = (contractId: EntityId) => lightJoin(publicContracts(), `${contractId}/`);
    export const contracts = () => '/mgmt/api/sales-contracts/';
    export const contract = (contractId: EntityId) => lightJoin(contracts(), `${contractId}/`);
    export const contractComment = (contractId: EntityId) => lightJoin(contract(contractId), `comment/`);
    export const subscriptions = () => '/mgmt/api/menu-subscriptions/';
    export const deleteSubscription = (subscriptionUid: EntityId) => `/mgmt/api/menu-subscriptions/${subscriptionUid}`;
    export const facilities = () => '/mgmt/api/facilities/';
    export const pricingProfiles = () => '/mgmt/api/pricing-profiles/';
    export const pricingProfile = (pricingProfileId: EntityId) => lightJoin(pricingProfiles(), `${pricingProfileId}/`);
    export const pricingRules = () => '/mgmt/api/pricing-rules/';
    export const pricingRule = (pricingRuleId) => lightJoin(pricingRules(), `${pricingRuleId}/`);
    export const pricingRulesOrder = (pricingProfileId: EntityId) =>
        lightJoin(pricingProfile(pricingProfileId), '/arrange-rule-order/');
    export const products = () => `/mgmt/api/products/`;
    export const auditActionComments = () => `/mgmt/api/sales-contracts-action-audits/`;
    export const cart = (orgUnitUid: EntityId) => `/api/cart/${orgUnitUid}/`;
    export const addToCart = (orgUnitUid: EntityId) => lightJoin(cart(orgUnitUid), `add/`);
    export const cartFinalCheckout = (orgUnitUid: EntityId) => lightJoin(cart(orgUnitUid), `final-checkout/`);
    export const cartCheckout = (orgUnitUid: EntityId) => lightJoin(cart(orgUnitUid), `checkout/`);
    export const clearCart = (orgUnitUid: EntityId) => lightJoin(cart(orgUnitUid), `clear/`);
    export const setShippingOptions = (orgUnitUid: EntityId) => lightJoin(cart(orgUnitUid), `set-shipping-options/`);
    export const getShippingOptions = (orgUnitUid: EntityId) => lightJoin(cart(orgUnitUid), `get-shipping-options/`);

    export const users = () => '/mgmt/api/users/';
    export const user = (userId: EntityId) => lightJoin(users(), `${userId}/`);
    export const drivers = () => '/mgmt/api/facilities/drivers/';
    export const driver = (driverId: EntityId) => lightJoin(drivers(), `${driverId}/`);

    export const allPermissions = () => lightJoin(users(), 'list-all-permissions/');
    export const revokePermission = (userId: EntityId) => lightJoin(users(), `${userId}/revoke-permission/`);
    export const addPermission = (userId: EntityId) => lightJoin(users(), `${userId}/grant-permission/`);
    export const setUserGroups = (userId: EntityId) => lightJoin(users(), `${userId}/set-groups/`);
    export const resetPassword = (userId: EntityId) => lightJoin(users(), `${userId}/set-password/`);

    export const publicInvoices = () => `/api/invoices/`;
    export const publicInvoice = (invoiceId: EntityId) => lightJoin(publicInvoices(), `${invoiceId}/`);
    export const publicInvoiceToken = (invoiceId: EntityId) =>
        lightJoin(publicInvoices(), `${invoiceId}/payment-token/`);
    export const processPayment = (invoiceId: EntityId) => lightJoin(publicInvoices(), `${invoiceId}/process-payment/`);
    export const publicInvoiceStatistics = (invoiceId: EntityId) =>
        lightJoin(publicInvoices(), `${invoiceId}/statistics`);
    export const staffInvoiceStatistics = (invoiceId: EntityId) => `/mgmt/api/invoices/${invoiceId}/statistics/`;
    export const staffInvoiceChangeContract = (invoiceId: EntityId) =>
        `/mgmt/api/invoices/${invoiceId}/change-contract/`;
    export const expiredOrdersUpdate = (invoiceId: EntityId) =>
        `/mgmt/api/invoices/${invoiceId}/update-expired-orders-tracking/`;

    export const staffOrders = (orderId: EntityId) => `/mgmt/api/orders/`;
    export const staffMarkOrderAsDelivered = (orderId: EntityId) =>
        lightJoin(staffOrders(orderId), `${orderId}/mark-delivered/`);

    export const orders = () => `/api/orders/`;
    export const orderById = (orderId: EntityId) => lightJoin(orders(), `${orderId}/`);

    export const inventoryAssets = () => '/mgmt/api/inventory/assets/';
    export const inventoryAsset = (assetId: EntityId) => lightJoin(inventoryAssets(), `${assetId}/`);
    export const inventoryVendors = () => '/mgmt/api/inventory/vendors/';
    export const slimAssets = () => '/mgmt/api/inventory/assets/all/';
    export const slimAsset = (assetId: EntityId) => lightJoin(slimAssets(), `${assetId}/`);

    export const manufacturers = () => '/mgmt/api/inventory/manufacturers/';

    export const componentAssets = () => '/mgmt/api/inventory/components/';
    export const componentAsset = (componentId: EntityId) => lightJoin(componentAssets(), `${componentId}/`);

    export const mealAssets = () => '/mgmt/api/inventory/meals/';
    export const mealAsset = (mealId: EntityId) => lightJoin(mealAssets(), `${mealId}/`);

    export const packageAssets = () => '/mgmt/api/inventory/container-packages/';
    export const packageAsset = (packageId: EntityId) => lightJoin(packageAssets(), `${packageId}/`);

    export const inventoryAllergens = () => '/mgmt/api/inventory/allergens/';

    export const warehouses = () => '/mgmt/api/facilities/';
    export const warehouse = (warehouseId: EntityId) => lightJoin(warehouses(), `${warehouseId}`);
    export const mealAssemblyPlan = (warehouseId: EntityId) =>
        lightJoin(warehouse(warehouseId), '/plan-meal-assembly/');
    export const packageAssemblyPlan = (warehouseId: EntityId) =>
        lightJoin(warehouse(warehouseId), '/plan-package-assembly/');
    export const assemblePlanMeal = (warehouseId: EntityId) => lightJoin(warehouse(warehouseId), '/assemble-meal/');
    export const assemblePlanPackage = (warehouseId: EntityId) =>
        lightJoin(warehouse(warehouseId), '/assemble-package/');
    export const procureAssetLot = (warehouseId: EntityId) => lightJoin(warehouse(warehouseId), '/purchase-asset/');

    export const assetLots = () => '/mgmt/api/asset-lots/';
    export const assetLot = (assetLotId: EntityId) => lightJoin(assetLots(), `${assetLotId}/`);
    export const markAssetLotReceived = (assetLotId: EntityId) => lightJoin(assetLot(assetLotId), `receive/`);
    export const makeStockAdjustment = (assetLotId: EntityId) => lightJoin(assetLot(assetLotId), `adjust-stock/`);
    export const lotTransferLocations = () => lightJoin(assetLots(), `transfer-locations/`);
    export const transferAssetLot = (assetLotId: EntityId) => lightJoin(assetLot(assetLotId), `transfer/`);
    export const requestAssetInventory = (assetLotId: EntityId) =>
        lightJoin(assetLots(), `${assetLotId}/request-inventory/`);
    export const changeUnitCost = (assetLotId: EntityId) => lightJoin(assetLots(), `${assetLotId}/update-unit-cost/`);

    export const mealSlots = () => '/mgmt/api/freshmeals/mealslots/';
    export const mealSlot = (mealSlotId: EntityId) => lightJoin(mealSlots(), `${mealSlotId}/`);

    export const servingMenus = () => '/mgmt/api/freshmeals/menuentries/';
    export const servingMenu = (servingMenuId: EntityId) => lightJoin(servingMenus(), `${servingMenuId}/`);
    export const servingMenuCompliance = (servingMenuId: EntityId) =>
        lightJoin(servingMenus(), `${servingMenuId}/calculate-compliance/`);

    export const menuTemplates = () => '/mgmt/api/freshmeals/menutemplates/';
    export const menuTemplate = (menuTemplateId: EntityId) => lightJoin(menuTemplates(), `${menuTemplateId}/`);

    export const warehouseRoutes = () => '/mgmt/api/freshmeals/weekly-routes/';
    export const warehouseRoute = (warehouseRouteId: EntityId) => lightJoin(warehouseRoutes(), `${warehouseRouteId}/`);

    // route sheets endpoint is the new endpoint for dispatch routes
    // it is being shared between each other
    export const routeSheets = () => `/mgmt/api/freshmeals/route-sheet/`;
    export const dispatchRoutes = () => '/mgmt/api/freshmeals/dispatch-routes/';
    export const dispatchRoute = (dispatchRouteId: EntityId) => lightJoin(dispatchRoutes(), `${dispatchRouteId}/`);
    export const reorderDispatchRoutes = () => '/mgmt/api/freshmeals/dispatch-routes/reorder-dispatch-routes/';
    export const dispatchRouteStop = (dispatchRouteStopId: EntityId) =>
        `/mgmt/api/freshmeals/dispatch-route-stops/${dispatchRouteStopId}/`;

    export const routeStops = () => '/mgmt/api/freshmeals/weekly-route-stops/';
    export const routeStop = (routeStopId: EntityId) => lightJoin(routeStops(), `${routeStopId}/`);

    export const deliverySites = () => '/mgmt/api/freshmeals/delivery-sites/';
    export const deliverySite = (deliverySiteId: EntityId) => lightJoin(deliverySites(), `${deliverySiteId}/`);

    export const driverRoutes = () => '/api/dispatch/driver/';

    export const purchaseAssetLot = (warehouseId: EntityId) =>
        lightJoin(warehouses(), `${warehouseId}/purchase-asset/`);

    export const auditLogs = () => `/mgmt/api/audit-logs/`;
    export const lotAdjustments = () => `/mgmt/api/lot-adjustments/`;
    export const auditLog = (auditLogId: EntityId) => lightJoin(auditLogs(), `${auditLogId}/`);

    export const totesReconcile = (siteId: EntityId) => `/mgmt/api/freshmeals/delivery-sites/${siteId}/tote-reconcile/`;
}
