import { catchError, delay, filter, map, switchMap } from 'rxjs/operators';
import { concat, forkJoin, of } from 'rxjs';
import axios from 'axios-observable';
import { PayloadEpic } from '@/store/epics/epic-types';
import { actions, asyncActions, selectors } from '@/store/slices';
import { apiEndpoints } from '@/store/constants';
import { withSelector } from '@/utils/rxjs-utils';

interface CartCheckoutResponse {
    uid: string;
}

export const postCartCheckoutEpic: PayloadEpic<void, string | unknown> = (action$, state$) => {
    return action$.pipe(
        filter(asyncActions.carts.postCartCheckout.match),
        switchMap(() => forkJoin([
            withSelector(state$, selectors.profile.selectedOrgUnit),
            withSelector(state$, selectors.publicContracts.selectedContract),
        ])),
        switchMap(([selectedOrgUnit, selectedContract]) => concat(
            of(actions.carts.setIsCheckingOut(true)),
            of(actions.carts.setCheckoutError(null)),
            cartCheckout(selectedOrgUnit, selectedContract).pipe(
                map(({ data }) => actions.carts.setCheckoutRedirectUrl(`/thank-you/${data.uid}`)),
                catchError((error) => {
                    console.error('postCartCheckoutEpic', error);
                    return of(actions.carts.setCheckoutError(error));
                }),
            ),
            of(actions.carts.setIsCheckingOut(false)),

            // if this happens too quickly, the cart doesn't empty!
            of(asyncActions.carts.fetchCurrent()).pipe(delay(1000)),
        )),
    );
};

const cartCheckout = (selectedOrgUnit, selectedContract) => {
    return axios.post<CartCheckoutResponse>(
        apiEndpoints.cartCheckout(selectedOrgUnit!.uid),
        null,
        { params: { sales_contract: selectedContract?.uid } },
    );
};
